
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddJobworkInvoiceModal from "@/components/modals/forms/AddJobworkInvoiceModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddJobworkInvoiceModal,
    AddProductListSalesEnquiry,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const listVisible = ref<boolean>(false);

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WICompanies>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          company_id: 0,
          search_term: data,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            JwtService.saveUserData({
              rakesh_count: data.rakesh_count,
              nikhil_count: data.nikhil_count,
              mansi_count: data.mansi_count,
            });
            console.log(JwtService.getUserData());

            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j]["company_verified_yes_no"]) {
                status_label = "Verified";
                status_color = "success";
              } else {
                status_label = "Unverified";
                status_color = "danger";
              }

              if (data.result_list[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                company_id: data.result_list[j]["company_id"],
                user: {
                  group: data.result_list[j]["company_group_type_name"],
                  avatar:
                    "https://cdn.elogicals.com/customers/" +
                    data.result_list[j]["company_id"] +
                    "/logo/" +
                    data.result_list[j]["company_id"] +
                    ".png",
                  name: data.result_list[j]["company_name"],
                },
                comp_type: data.result_list[j]["company_business_type_name"],
                status: {
                  label: status_label,
                  color: status_color,
                },
                active: {
                  label: active_label,
                  color: active_color,
                },
                roc: data.result_list[j]["company_roc_name"],
                nob: data.result_list[j]["company_nob_name"],
                doi: data.result_list[j]["company_doi"],
                pan: data.result_list[j]["company_pan"],
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            // tableData.value = [{
            //   company_id: 1,
            //   user: {
            //     avatar: "string;",
            //     name: "string;",
            //     group: "string;",
            //   },
            //   comp_type: "string;",
            //   status: {
            //     label: "string;",
            //     color: "string;",
            //   },
            //   active: {
            //     label: "string;",
            //     color: "string;,"
            //   },
            //   roc: "string,",
            //   nob: "string,",
            //   doi: "string,",
            //   pan: "string,"
            // }];

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Jobwork Invoice", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].company_id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      hideList,
    };
  },
});
